import React, { useMemo, useEffect } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useDelayedRender from "@hooks/useDelayedRender"
import loadable from "@loadable/component";
import useWindowDimensions from "@hooks/useWindowDimensions";
import backgroundImage from "@images/about-us/backgroundImage.jpg";
import backgroundImageMobile from "@images/about-us/backgroundImageMobile.jpg";
import LinkButton from "@components/Common/LinkButton";
import { 
  VIDEO_SECTION_TITLE, 
  SECOND_CATCH_UP_SECTION_TITLE,
  SECOND_CATCH_UP_SECTION_DESCRIPTION,
  NAGIVATION_BUTTONS_CONFIG, 
  CUSTOM_BADGES
} from "./constants";
import { StyledAbout, StyledButtonsContainer } from "./styled";

const VideoSection = loadable(() =>
  import("@common/CommonSections/VideoSection/VideoSection")
);

const CrawlableSecondCatchUpSection = loadable(() =>
  import("@common/CommonSections/CrawlableSecondCatchUpSection")
);

const AboutSection = loadable(() =>
  import("./components/AboutSection/AboutSection")
);

const VisionSection = loadable(() =>
  import("./components/VisionSection/VisionSection")
);

const CoreValuesSction = loadable(() =>
  import("./components/CoreValuesSection/CoreValuesSection")
);

const FoundersSection = loadable(() =>
  import("./components/FoundersSection/FoundersSection")
);

const StorySection = loadable(() =>
  import("./components/StorySection/StorySection")
);

const TeamSection = loadable(() => import("./components/TeamSection"));

const ProudSection = loadable(() =>
  import("@common/CommonSections/ProudSection/ProudSection")
);

const About = () => {
  const { isMobile, width } = useWindowDimensions();
  const { isDelayed } = useDelayedRender(100);

  useEffect(
    () => () => {
      ScrollTrigger.getAll().forEach(st => st.kill());
    },
    []
  );

  const isMounted = useMemo(() => width !== 0, [width]);

  return (
    isMounted && (
      <StyledAbout>
        <VideoSection
          backgroundImage={isMobile ? backgroundImageMobile : backgroundImage}
          title={VIDEO_SECTION_TITLE}
          description={
            <>
                Impressit is a team of ambitious, innovative, and
                confident professionals in the technology industry.
                We cherish creativity and freedom to look for
                unconventional solutions for any software project.
                At the same time, we focus on following structured
                processes and industry best practices to deliver the
                best solutions to our clients.
                <br />
                <br />
                And most importantly — we love what we do and never
                settle for something less than impressive.
            </>
        }
        />
        {isDelayed && (
        <>
        <AboutSection />
        <VisionSection />
        <CoreValuesSction />
        <FoundersSection />
        <StorySection />
        <TeamSection />
        {isMobile !== undefined && !isMobile && <ProudSection customBadges={CUSTOM_BADGES} />}
        <CrawlableSecondCatchUpSection
          title={SECOND_CATCH_UP_SECTION_TITLE}
          description={SECOND_CATCH_UP_SECTION_DESCRIPTION}
          hasBigImage
        >
          <StyledButtonsContainer>
            {NAGIVATION_BUTTONS_CONFIG.map(({ text, to, styles }) => (
              <LinkButton to={to} styles={styles}>
                {text}
              </LinkButton>
            ))}
          </StyledButtonsContainer>
        </CrawlableSecondCatchUpSection>
        </>
        )}
      </StyledAbout>
    )
  );
};

export default About;
