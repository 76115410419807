import { GLOBAL_APPLICATION_INTERNAL_LINKS } from "@links"
import { BADGES } from "../../constants"
import coreValuesBackgroundFirst from "@images/about-us/coreValuesBackgroundFirst.png"
import coreValuesBackgroundSecond from "@images/about-us/coreValuesBackgroundSecond.png"
import coreValuesBackgroundThird from "@images/about-us/coreValuesBackgroundThird.png"
import coreValuesBackgroundFourth from "@images/about-us/coreValuesBackgroundFourth.png"
import coreValuesBackgroundFifth from "@images/about-us/coreValuesBackgroundFifth.png"

export const VIDEO_SECTION_TITLE = "About us"
export const SECOND_CATCH_UP_SECTION_TITLE = "Get in touch"
export const SECOND_CATCH_UP_SECTION_DESCRIPTION =
  "Complete contact form, and we will contact you within 24 hours"

export const NAGIVATION_BUTTONS_CONFIG = [
  {
    text: "Contact us",
    to: GLOBAL_APPLICATION_INTERNAL_LINKS.CONTACT_US,
    styles: {
      normal: {
        border: ".0625rem solid #c4b18099",
        background: "#c4b1804d",
      },
      hover: {
        "border-color": "var(--main-gold)",
      },
    },
  },

  {
    text: "Careers",
    to: GLOBAL_APPLICATION_INTERNAL_LINKS.CAREERS,
    styles: {
      normal: {
        border: ".0625rem solid #ffffff4d",
      },

      hover: {
        "border-color": "#fff",
        "background-color": "rgba(255, 255, 255, 0.1)",
      },
    },
  },
]

export const CUSTOM_BADGES = [
  { index: 0, ...BADGES.clutchGlobalSpring },
  { index: 1, ...BADGES.clutchGenerativeAi },
  { index: 2, ...BADGES.clutchFastestGrowth },
  { index: 3, ...BADGES.goodFirms },
  { index: 4, ...BADGES.wadline },
  { index: 5, ...BADGES.upcity },
  { index: 6, ...BADGES.manifest },
  { index: 7, ...BADGES.developers },
]

export const CORE_VALUES = [
  {
    title: "Do great or do nothing!",
    backgroundImage: coreValuesBackgroundFirst,
    description: `
            Are you good at sport, cooking or even building rockets? That's awesome! 
            We are great at building bespoke software solutions,
            so leave it for us cause we never provide mediocre results.
            Do great or do nothing!
        `,
  },
  {
    title: "Openness",
    backgroundImage: coreValuesBackgroundSecond,
    description: `
            We are open to our clients and colleagues, to new ideas and points of 
            view. We are ready to listen and to hear. We accept the change and are 
            ready to bring change to the world.
        `,
  },
  {
    title: "Always deliver on commitments",
    backgroundImage: coreValuesBackgroundThird,
    description: `
           We are Impressit - a team of creative thinkers and game-changers 
           who are confident in every project and do whatever it takes to always 
           deliver on commitments. Impressit always delivers.
        `,
  },
  {
    title: "Creative freedom",
    backgroundImage: coreValuesBackgroundFourth,
    description: `
            We’re striving to become the free space for creating and experimenting — both
             for our team and our clients. We believe in the power of free mind and spirit.
        `,
  },
  {
    title: "Fun",
    backgroundImage: coreValuesBackgroundFifth,
    description: `
         Here at Impressit, a great sense of humor is of the essence in building a productive 
         and healthy atmosphere between co-workers and our partners.
        `,
  },
]
