import styled from "styled-components";
import { MOBILE_MIN_WIDTH } from "@constants";

export const StyledAbout = styled.div`
    & .main-button--header {
        &:first-child {
            margin-right: 1.5rem;
        }

        @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
            margin-bottom: 1.5rem;
        }
    }
`;

export const StyledButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 30px;

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        flex-direction: column;
    }
`;
